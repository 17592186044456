
import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField'
import { useNavigate } from "react-router-dom";
const Index = (props) => {

    const navigate = useNavigate();

    return (
        <>
            <div className="container-fluid d-flex flex-column align-items-center">
                <div className="col-md-12">
                    <div className="d-flex justify-content-center mt-2" >
                        <img height={300} src="/images/restrict.png" alt="" />
                    </div>
                    <div className="text-center p-2" style={{ margin: "20px auto", maxWidth: "250px" }}>
                        {/* Centered div with left-aligned text */}
                        <div style={{ textAlign: "left", width: "255px" }}>
                            <span className="sorry" style={{ fontSize: "40px" }} >Sorry!<br /></span>
                            <span className="restricted"><br />Access to this page has been </span><br />
                            <span className="restricted error">restricted</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <button onClick={() => { navigate("/scan") }} >Scan Another Code</button>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Index;
