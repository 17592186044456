
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { d1, scanUrl } from '../../components/utils';
const Index = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (!state) {
            navigate("/");
        }
    }, [state])

    const checkDomain = () => {
        const currentUrl = window.location.href;
        if (currentUrl.includes(d1)) {
            navigate("/scan");
        } else {
            window.open(`${scanUrl}`, "_self")
        }
    }

    return (
        <>
            <div className="col-md-12">
                <div className="d-flex justify-content-center mt-2" >
                    <img height={300} src="/images/restrict.png" alt="" />
                </div>
                <div className="text-center p-2" style={{ margin: "20px auto", maxWidth: "250px" }}>
                    {/* Centered div with left-aligned text */}
                    <div style={{ textAlign: "left", width: "255px" }}>
                        <span className="sorry" style={{ fontSize: "40px" }} >Sorry!<br /></span>
                        <span className="restricted"><br />{state}</span><br />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <button onClick={checkDomain} >Click here to report again</button>
                </div>
            </div>
        </>
    );
};

export default Index;
