import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ModelOpenToConform from "./ModelOpenToConform";
import { prod_url, scanUrl } from "../../components/utils";
import Header from '../../components/header'
const WebCall = () => {
  const { state } = useLocation();
  const [openModel, setOpenModel] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleModelClose = () => {
    setOpenModel(false);
  };
  const handleModelOpen = () => {
    setOpenModel(true);
  };

  const makeCall = async () => {
    handleModelClose();
    try {
      let res = await axios.post(prod_url + "report/create", {
        reporterPhone: state,
        reportUrl: localStorage.getItem("link"),
        name: "",
      });
      if (!res.data.success) {
        navigate('/error', { state: res.data.message });
      } else {
        setSuccess(true);
      }
    } catch (Err) {
      console.log("Err", Err)
    }
  };

  useEffect(() => {
    if (state) {
      makeCall();
    } else {
      navigate("/");
    }
  }, [state]);


  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      // Replace '/home' with the route you want to navigate to
      // navigate('/home');
    };

    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, [navigate]);


  const openDialer = () => {
    window.location.href = `tel:112`;
  };



  return (
    <div className="container-fluid d-flex flex-column align-items-center ml-2">
      <div>
        <Header image={"call"} height={150} header={"Starting to connect you on a conference call"} subColor="red" subHeader={"This call is being recorded."} />
        <div className="d-flex" style={{ gap: 10, marginTop: "-40px" }}>
          <img height={140} src={"/images/emercall.png"} alt="" className="mt-1" />
          <div className="">
            <p className="ermerText">Connected<br />
              <span className="emerGreenText"> +91 {state}</span></p>
            <br />
            <p className="ermerText"> Connecting the available<br />
              <span className="emerGreenText"  > Emergency Contacts</span></p>
            <div className="sorry mt-1">
              <p className="" style={{ fontSize: "20px" }} > Call Dropped ?</p>
              <p className="error" style={{ marginTop: "-10px", fontSize: "16px", fontWeight: "400" }} > Need to reconnect ?</p>
              <div className="d-flex "> </div>
              <button className="reconnect mr-4" onClick={handleModelOpen} >Reconnect</button>
              <button className="reconnect" onClick={openDialer} >Dial 112</button>
            </div>
          </div>
        </div>
      </div>
      <ModelOpenToConform
        handleClose={handleModelClose}
        makeCall={() => {
          makeCall();
        }}
        open={openModel}
      />
    </div>
  );
};

export default WebCall;

