import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { prod_url, reportUrl } from "../../../components/utils";
const Index = () => {
    const { user_url } = useParams();
    console.log("user",user_url)
    const navigate = useNavigate();
    const [isShow, setShow] = useState(false);
    const getQR = async () => {
        try {
            let ress = await axios.post(prod_url + "qr/getQR", {
                url: `${reportUrl}` + user_url,
            });
            let chk = ress?.data?.data;
            if (!chk?.mobile) {
                setShow(true)
                localStorage.setItem("scan", JSON.stringify(ress?.data?.data));
            } else {
                navigate('/restricted', { state: "Invalid Code" });
            }
        } catch (err) {
            navigate('/restricted', { state: err?.response?.data?.message });
        }
    };

    const changeState = () => {
        setShow(true);
    }

    useEffect(() => {
        if (user_url) {
            getQR();
        } else {
            changeState();
        }
    }, [user_url]);


    return (
        <>
            {isShow ? <div className="container-fluid d-flex flex-column align-items-center">
                <div className="d-flex justify-content-start mt-5 mb-5">
                    <img height={200} src="/images/proceed.png" alt="" />
                </div>
                <div className="mb-5 mt-2 px-1" style={{ width: "255px" }}>
                    <span className="restricted" >Hey! Looks like this code has not been claimed as yet.<br /></span>
                    <span className=""><br />If you are the rightful owner  </span><br />
                    <span>you can proceed to claim this code.</span>
                </div>
                <div className="mt-2">
                    <button onClick={() => navigate("/confirm")} >Proceed To Claim</button>
                </div>
            </div> : ""
            }
        </>
    );
};

export default Index;
