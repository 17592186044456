
import React, { useState } from "react";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";

const Scanner = (props) => {
    const [type, setType] = useState();
    const [err, setErr] = useState('');
    const navigate = useNavigate();

    const redirect = () => {
        if (type == "qr") {
            navigate('/scan')
        } else if (type == "vehicle") {
            navigate('/vehicle-no')
        } else {
            setErr("Please select 1 of the options above to proceed")
        }
    }

    const changeType = (e) => {
        setType(e);
        setErr("");
    }

    return (
        <>
                <div className="container-fluid d-flex flex-column align-items-center">
                    <div className="mt-2">
                        <Header image={"ecomm"} height={200} header={"Report An Event"} subHeader={"How would you like to report?"} />
                    </div>
                    <div className="mb-2" style={{marginRight:"10px"}}>
                        <div className="mr-4">
                            <input className="mr-4" type="radio" onChange={() => { changeType('qr') }} checked={type === "qr"} name="scan" />
                            <button onClick={() => { changeType('qr') }} className="radioButton" >Scan Qr Code</button>
                        </div>
                        <div>
                            <input className="mr-4" type="radio" name="scan" onChange={() => { setType('vehicle') }} checked={type === "vehicle"} />
                            <button onClick={() => { changeType('vehicle') }} className="radioButton" >Enter Vehicle Number</button>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="contain_error">
                            <p >{err}</p>
                        </div>
                        <div>
                            <button onClick={redirect} style={{ width: "300px" }} width >Proceed to Report</button>
                        </div>
                        <div style={{ fontSize: "12px", width: "300px" }}>
                            <p>If you cannot locate the QR code on the Vehicle, you can enter the Vehicle Number</p>
                        </div>
                    </div>
                </div>
            </>
    );
};

export default Scanner;
