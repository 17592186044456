import React from 'react';

const Index = () => {
  return (
    <div className='desktop__error'>
      This content only available in mobile.
    </div>
  );
};

export default Index;