import './assets/css/index.css';
import Router from './router';
import DesktopError from './pages/desktopError';
import { useEffect, useState } from 'react';
function App() {
  const [isMobile, setIsMobile] = useState(true);
  const pathName = window.location.pathname;

  useEffect(() => {
    // Function to update window width
    const updateWindowWidth = () => {
      if (window.innerWidth >= 320 && window.innerWidth <= 767) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    };
    // Add event listener for window resize
    window.addEventListener('resize', updateWindowWidth);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <div>
      {isMobile ?
        <>
          {pathName !== "/test" ? <div className="container-fluid d-flex flex-column align-items-center">
            <div className='logo-place'>
              <img height={80} src='/images/logo.png' alt="" />
            </div>
          </div> : ""}
          <Router />
        </>
        : (
          <DesktopError />
        )
      }
    </div >

  );
}

export default App;
