
import React, { useState, useEffect, useRef } from "react";
import QrReader from "modern-react-qr-reader";
import {
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { d2, prod_url, } from "../../../components/utils";
const Scanner = () => {
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const getQR = async () => {
    try {
      let ress = await axios.post(prod_url + "qr/getQR", {
        url: data,
      });
      let chk = ress?.data?.data;
      if (chk?.mobile) {
        window.open(`https://${chk?.reportUrl}`, "_blank")
      } else {
        localStorage.setItem("scan", JSON.stringify(ress?.data?.data));
        navigate({ pathname: "/proceed" });
      }
    } catch (err) {
      navigate("/restricted");
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (data.length) {
      getQR();
    }
  }, [data]);

  const scanData = (result) => {
    console.log("das", result)
    if (result && !result?.includes(d2)) {
      window.open(result, '_self')
    }
    if (!!result) {
      setData(result);
    }
  };

  return (
    <>
      <div className="container-fluid d-flex flex-column align-items-left mt-4">
        <div style={{ height: "280px" }}>
          <QrReader
            className="qr-image-wrapper"
            facingMode={"environment"}
            onScan={(e) => scanData(e)}
            delay={1000}
            onError={(d) => {
              console.log("scan err", d);
            }}
            style={{ height: "100px" }}
          />
        </div>
        <div className="addVehicle__message" style={{ marginTop: "180px" }}>
          <button>Waiting for you to scan</button>
        </div>
        <div style={{ margin: "5px auto", maxWidth: "250px" }}>
          <p>Only a valid code on the network would get scanned.</p>
        </div>
      </div>
    </>
  );
};

export default Scanner;
