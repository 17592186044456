import React from 'react'
import Header from '../../components/header'

const LoginWithNumber = (props) => {

    const OpenWhatsapp = () => {
        window.open(`https://wa.me/919137416783?text=Press Enter to send :${props?.code}`, "_blank");
    }
    const currentUrl = window.location.href.includes("112.")
    return (
        <>
            <div className="container-fluid d-flex flex-column align-items-center ml-2">
                <div>
                    <Header image="Globe" height={150} header="Mobile Validation" subHeader="Please enter your number to proceed." />
                    <div className="d-flex justify-content-start" style={{ gap: "10px", marginTop: "-25px" }}>
                        <input className="input-field" style={{ width: "66px" }} type="tel" value="+91" name="scan" placeholder="" />
                        <input className="input-field" maxLength={10} minLength={10} style={{ width: "190px" }} value={props?.customerNumber}
                            onChange={(event) => props?.handleChangeMobile(event)} type="tel" name="scan" placeholder="" />
                    </div>
                    {props?.customerNumber && props?.customerNumber?.length < 10 ? <p className="d-flex justify-content-start mt-2 error-input">Invalid Number</p> : ""}
                    <div className="mt-4" >
                        <button style={{ width: "270px", border: "#003C25 1px solid", color: "black", background: "transparent" }} onClick={() => props?.getMobileNumberToOtp()} >Send OTP</button>
                    </div>
                    <hr style={{ border: "1px solid rgba(0, 0, 0, .4)" }} />
                    {currentUrl ? <>
                        <p style={{ marginTop: "20px", fontSize: "16px", width: "245px", fontWeight: "600", color: "black" }}>
                            My number is on WhatsApp and receives regular SIM calls.
                            {/* <b>Click Here</b> */}
                        </p>
                        <div className=" mt-2" >
                            <button style={{ width: "270px" }} onClick={() => OpenWhatsapp()} >Report using WhatsApp</button>
                        </div>
                    </> : ""}
                </div>
            </div >
        </>
    )
}

export default LoginWithNumber