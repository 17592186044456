import React, { useState } from "react";
import OTPInput from "otp-input-react";
import Header from "../../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Confirm = () => {

    const [OTP, setOTP] = useState("");
    const navigate = useNavigate();
    const scanData = localStorage.getItem("scan");
    const [err, setErr] = useState(false);


    const navigateSuccess = () => {
        if (OTP === JSON.parse(scanData)?.password?.toString()) {
            navigate("/login")
        } else {
            setErr(true);
        }
    };



    return (
        <div className="container-fluid d-flex flex-column align-items-center">
            <div className="mt-3">
                <Header image="password" height={200} content="center" header="Password Please" subHeader="You would have received an activation password along with the code" />
                <div className="d-flex justify-content-start" style={{ gap: "10px" }}>
                    <OTPInput
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        className="otp__Input"
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                    />
                </div>
                {err ? <p className="d-flex justify-content-start mt-2 error-input">Invalid password</p> : ""}
                <div className="mt-4 "  >
                    <button onClick={navigateSuccess} className="mt-5" style={{ width: "327px", }}  >Confirm</button>
                </div>
            </div>
        </div >
    );
};

export default Confirm;
