import React from 'react';
import { Box, Modal } from '@mui/material';


const ModelOpenToConform = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props?.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className="modal__style"
                style={{ minWidth: "310px", height: "200px" }}
            >
                <h3 className='restricted' >Are you sure you want to reconnect?</h3>
                <div className='conforming__cancleAndConfirm__btn' style={{ marginTop: "30px" }}>
                    <button onClick={() => props?.handleClose()} className='reconnect'>
                        <img src="/images/phone-cross.svg" />
                    </button>
                    <button className='reconnect' style={{borderColor:"#117800"}} onClick={() => props.makeCall()}>
                        <img src="/images/phone-call.svg" />
                    </button>
                </div>
            </Box>
        </Modal >
    )
}

export default ModelOpenToConform