import React, { useEffect, useState } from "react";
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { prod_url, d1, d2, reportUrl, webUrl, secrertkey } from "../../components/utils";
import Header from "../../components/header";
import * as CryptoJS from 'crypto-js'


const OtpVerfication = (props) => {
  const [OTP, setOTP] = useState("");
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(120);

  const handleOTP = async () => {
    try {
      let typee = "";
      if (window.location.host?.includes(d1)) {
        typee = "add";
      }
      const userData = localStorage?.getItem("scan");
      let url = userData
        ? JSON.parse(userData)?.reportUrl
        : `${reportUrl}${props.user_url}`;
      if (OTP?.length === 4) {
        let res = await axios.post(prod_url + "user/verifyotp", {
          sessionId: props.sessionId,
          otp: OTP,
          mobile: props?.customerNumber,
          qrcode: url,
          type: typee,
        });
        if (typee !== "add") {
          if (res.data.success) {
            localStorage.setItem("link", url);
            navigate("/webcall", { state: props.customerNumber });
          } else {
            setErr(true);
          }
        } else {

          var encrypted = CryptoJS.AES.encrypt(JSON.stringify({ "phone": props.customerNumber }), secrertkey).toString();
          const bytes = CryptoJS.AES.decrypt(encrypted, secrertkey)
          const plainText = bytes.toString(CryptoJS.enc.Utf8)
          window.open(`${webUrl}/auth/signup?ssid=${encrypted}`, "_self");
        }
      } else {
        setErr(true);
      }
    } catch (err) {
      // console.log("err", err)
      if (err?.response?.data?.Status === "Error") {
        setErr(true)
      } else {
        alert(err.response?.data.message);
      }
    }
  };


  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(timerInterval);
        // Perform any action when the timer reaches 0
        console.log('Timer reached 0 seconds');
      }
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [seconds]);

  const subHeader = `Please enter the OTP sent to +91 ${props.customerNumber}.`

  return (
    <div className="container-fluid d-flex flex-column align-items-center ml-4">
      <div>
        <Header image="pass" height={200} header="OTP Confirmation" subHeader={subHeader} />
        {window.location.host?.includes(d2) ? <p style={{ marginTop: "-40px", fontSize: "16px", width: "327px", fontWeight: "400" }}><br />
          Once the otp is verified,<br />
          you will receive a call from
          <b style={{ fontSize: "18px", width: "327px", fontWeight: "600" }}><br />
            022-5064 7347
          </b>
        </p> : ""}
        <div className="d-flex justify-content-start" style={{ gap: "10px" }}>
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            className="otp__Input"
            OTPLength={4}
            otpType="number"
            disabled={false}
          />
        </div>
        {err ? <p className="d-flex justify-content-start mt-3 error-input">OTP did not match</p> : ""}
        <div className="mt-4 "  >
          <button style={{ width: "300px" }} onClick={() => handleOTP()} >Confirm</button>
        </div>
        {seconds > 0 ? <p className='mt-1' style={{ width: "330px" }}>
          Resend OTP in {seconds} seconds
        </p> :
          <p onClick={() => { props.getMobileNumberToOtp(); setSeconds(120) }} className='mt-1' style={{ width: "330px" }}>Resend OTP</p>
        }
      </div>
    </div >
  );
};

export default OtpVerfication;
