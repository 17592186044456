
import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import { isValidVehicleNumberPlate } from "../../../components/validation";
import axios from "axios";
import { prod_url, reportUrl } from "../../../components/utils";

const Index = () => {
    const [no, setNo] = useState("");
    const [no1, setNo1] = useState("");
    const [err, setErr] = useState(false);
    const [err1, setErr1] = useState(false);
    const [Loading, setLoading] = useState(false);

    const checkVehicleNo = async () => {
        try {
            if (!isValidVehicleNumberPlate(no) || !no1 || (no !== no1)) {
                return false;
            }
            setLoading(true);
            let ress = await axios.post(prod_url + `vehicle/getByNo?no=${no1}`);
            let chk = ress?.data?.data;
            if (chk?.reportUrl) {
                window.open(`https://${chk.reportUrl}`, "_self");
                setErr1(false);
            } else {
                setErr1(true);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setErr1(true);
        }
    };

    const setandValidate = (val) => {
        let value = val?.toUpperCase();
        let checkVal = isValidVehicleNumberPlate(value);
        if (checkVal && val.length === 10) {
            setErr(false);
        } else {
            setErr(true);
        }
        setNo(replaceSpaces(value))
    }

    const replaceSpaces = (val) => {
        return val?.replace(/\s/g, "")
    }

    return (
        <>
            <div className="container-fluid d-flex flex-column align-items-center">
                <div className="mt-2">
                    <Header image={"search"} height={150} header={"Vehicle Validation"} subHeader={"Please enter the vehicle number carefully"} />
                </div>
                <div className="" >
                    <input className="input-text" maxLength={10} minLength={10} style={{ width: "320px" }} type="text" name="scan" placeholder="Enter Vehicle Number" value={no} onChange={(e) => { setandValidate(e.target.value) }} />
                    {err ? <p className="d-flex justify-content-end error-input">Number Invalid</p> : ""}
                </div>

                <div className="d-grid mt-4" >
                    <input className="input-text" maxLength={10} minLength={10} style={{ width: "320px" }} type="text" name="scan" placeholder="Re-enter Vehicle Number" value={no1} onChange={(e) => { setNo1(replaceSpaces(e.target.value?.toUpperCase())) }} />
                    {(no1 && (no !== no1) || err) ? <p className="d-flex justify-content-end error-input mt-1">Number mismatch</p> : ""}
                    {no && no1 && no === no1 && !err ? <p className="d-flex justify-content-end primary mt-1">Match</p> : ""}
                </div>
                <div >

                </div>
                <div className="d-flex mt-4">
                    <button disabled={Loading} onClick={checkVehicleNo} style={{ width: "320px" }} >Continue</button>
                </div>
                {err1 ? <div className="contain_error">
                    <p >Unfortunately, the number entered is not registered with us</p>
                </div> : ""}
            </div>
        </>
    );
};

export default Index;
