import { useEffect } from "react";
import { scanUrl } from "../components/utils";

const RedirectPage = () => {
  useEffect(() => {
    window.location.replace(scanUrl);
  }, []);
};

export default RedirectPage;
