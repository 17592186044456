import React, { useEffect, useState } from "react";
import OtpVerfication from "./OtpVerfication";
import LoginWithNumber from "./LoginWithNumber";
import axios from "axios";
import { prod_url, reportUrl, scanUrl } from "../../components/utils";
import { useNavigate, useParams } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { user_url } = useParams();
  const [loginStep, setLoginStep] = useState(0);
  const [customerNumber, setCustomerNumber] = useState("");
  const [sessionId, setsessionId] = useState("");
  const [isShow, setShow] = useState(false);
  const handleLoginStep = (step) => {
    setLoginStep(step);
  };

  const handleChangeMobile = async (e) => {
    if (e.target.value.length <= 10) {
      setCustomerNumber(e.target.value);
    }
  };

  const getQR = async () => {
    try {
      let ress = await axios.post(prod_url + "qr/getQR", {
        url: `${reportUrl}` + user_url,
      });
      let chk = ress?.data?.data;
      if (chk?.mobile) {
        localStorage.setItem("scan", JSON.stringify(ress?.data?.data));
        setShow(true);
      } else {
        window.open(`${scanUrl}/proceed/${user_url}`, "_self")
      }
    } catch (err) {
      navigate('/error', { state: err?.response?.data?.message });
    }
  };


  const changePage = () => {
    const userData = localStorage?.getItem("scan");
    const currentUrl = window.location.href;
    if (!userData && !currentUrl.includes('addcode')) {
      navigate("/");
    }
    setShow(true)
  }

  console.log("hello");
  useEffect(() => {
    if (user_url) {
      getQR();
    } else {
      changePage();
    }
  }, [user_url]);

  const getMobileNumberToOtp = async () => {
    if (customerNumber?.length === 10) {
      let res = await axios.post(prod_url + "user/registerotp", {
        mobile: customerNumber,
        type: user_url ? "report" : "otp"
      });
      setsessionId(res.data?.data?.sessionId);
      handleLoginStep(1);
    } else {
      alert("Please Enter Mobile Number");
    }
  };

  return (
    <>
      {isShow ? <div>
        {loginStep === 0 ? (
          <LoginWithNumber
            code={user_url}
            customerNumber={customerNumber}
            getMobileNumberToOtp={getMobileNumberToOtp}
            handleChangeMobile={handleChangeMobile}
            user_url={user_url}
          />
        ) : loginStep === 1 ? (
          <OtpVerfication
            customerNumber={customerNumber}
            getMobileNumberToOtp={getMobileNumberToOtp}
            sessionId={sessionId}
            user_url={user_url}
          />
        ) : (
          <></>
        )}
      </div> : ""}
    </>

  );
};

export default Login;
