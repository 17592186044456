export const isValidVehicleNumberPlate = (NUMBERPLATE) => {
    // Regex to check valid
    // Indian Vehicle Number Plate
    let regex = new RegExp(/^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/);
    let regex2 = new RegExp(/^[A-Za-z0-9]{2}[A-Za-z]{2}[0-9]{4}[A-Za-z]{1,2}$/);
    // Indian Vehicle Number Plate
    // is empty return false
    if (NUMBERPLATE == null) {
        return "false";
    }

    // Return true if the NUMBERPLATE
    // matched the ReGex
    if (regex.test(NUMBERPLATE)  || regex2.test(NUMBERPLATE)) {
        return true;
    }
    else {
        return false;
    }
}