
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Index = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="container-fluid">
                <div className="row d-flex align-items-center">
                    <div className="col-md-12 mt-2 text-center">
                        <img height={200} width={250} src="/images/final.png" alt="" className="logo" />
                        <div className="text-center p-2" style={{ margin: "60px auto", maxWidth: "250px" }}>
                            {/* Centered div with left-aligned text */}
                            <div style={{ textAlign: "left", width: "255px" }}>
                                <span className="sorry">Hello!<br /></span>
                                <span className="restricted" style={{ fontWeight: "400", fontSize: "16px" }}><br />Welcome to the </span>
                                <span className="restricted">Emergency Contacts Calling Platform</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={()=>navigate('/report-type')}>Click here to proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
