import React, { useEffect, useState } from 'react';
import './wa.css';
import axios from 'axios';
import { prod_url } from '../components/utils';
import { isValidVehicleNumberPlate } from '../components/validation';

const isValidPNR = (pnr) => /^\d{10}$/.test(pnr); // Example validation for 10-digit PNR

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        mode: 'FASTAG',
        value: '',
        otp: '',
        sessionId: ''
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Loader state
    const [serverMessage, setServerMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [timer, setTimer] = useState(0); // 60 seconds timer
    const [isotpSent, setOtpSent] = useState(false);

    useEffect(() => {
        let interval = null;
        if (timer > 0) {
            // Start the timer when OTP is sent successfully
            interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(interval); // Stop the timer when it reaches 0
                        return 0;
                    }
                    return prev - 1; // Decrease the timer by 1 each second
                });
            }, 1000); // Decrease every second
        }
        return () => clearInterval(interval); // Clean up the interval when the component is unmounted or successMessage changes
    }, [successMessage, timer]);


    useEffect(() => {
        setTimeout(() => {
            setSuccessMessage('');
        }, 4000)
    }, [successMessage])


    useEffect(() => {
        setTimeout(() => {
            if (serverMessage?.type === "success")
                window.location.reload();
        }, 5000)
    }, [serverMessage])

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedValue = name === 'value' ? value.toUpperCase() : value;
        if (name === 'mode') {
            setFormData({
                ...formData,
                [name]: value,
                value: '',  // Clear the value field when mode changes
            });

        } else {
            setFormData({
                ...formData,
                [name]: updatedValue,
            });
        }


        // Real-time validation for each field
        let fieldErrors = { ...errors };

        if (name === 'name') {
            fieldErrors.name = value.length > 3 ? '' : 'Name must be more than 3 characters';
        }

        if (name === 'mobile') {
            fieldErrors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number must be exactly 10 digits';
        }

        if (name === 'value') {
            if (formData.mode === 'FASTAG') {
                fieldErrors.value = isValidVehicleNumberPlate(value) ? '' : 'Invalid vehicle number';
            } else if (formData.mode === 'IRCTC') {
                fieldErrors.value = isValidPNR(value) ? '' : 'PNR number must be exactly 10 digits';
            }
        }
        if (name === "mode") {
            fieldErrors.value = '';
        }

        if (name === 'otp') {
            fieldErrors.otp = /^\d{4}$/.test(value) ? '' : 'OTP must be 4 digits';
        }


        setErrors(fieldErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (Object.values(errors).some((error) => error)) {
            return; // Prevent form submission if there are errors
        }
        setIsLoading(true);
        setServerMessage('');
        try {
            if (!formData.otp) {
                const res = await axios.post(`${prod_url}user/registerotp`, { mobile: formData.mobile, type: "otp" });
                setSuccessMessage(isotpSent ? 'OTP resent successfully!' : 'OTP sent successfully!');
                setTimer(60)
                setFormData({
                    ...formData,
                    sessionId: res.data.data.sessionId,
                    otp: '', // Clear OTP field
                });
                setOtpSent(true)
            } else {
                await axios.post(`${prod_url}whatsapp/create`, formData);
                setServerMessage({ type: 'success', message: 'Form submitted successfully!' });
            }
        } catch (e) {
            const { response: { data } } = e;
            setServerMessage({ type: 'error', message: data ? data.message : 'An error occurred while submitting the form. Please try again later.' });
        } finally {
            setIsLoading(false); // Hide loader after submission attempt
        }
    };

    return (
        <div className='mainContainer'>
            <div className="image-container">
                <img src="/images/wa-bg.png" alt="Top Image" />
            </div>
            <div className="form-container">
                <div className="form-wrapper">
                    <h2 className="form-title">Registration</h2>
                    <h6 style={{ margin: "20px 0px 0px 0px", fontSize: "14px", fontWeight: 300 }}>Please complete this short form</h6>
                    <form onSubmit={handleSubmit} className="form">
                        <div className='form-wrapper1'>
                            <input
                                type="text"
                                name="name"
                                placeholder="Full Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className={`form-input ${errors.name ? 'input-error' : ''}`}
                            />
                            {errors.name && <p className="error-message">{errors.name}</p>}
                        </div>
                        <div className='form-wrapper1'>
                            <input
                                type="tel"
                                name="mobile"
                                placeholder="Mobile Number"
                                value={formData.mobile}
                                onChange={handleChange}
                                onInput={(e) => {
                                    // Only allow digits and limit the length to 10
                                    const newValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10); // Remove non-numeric characters and limit to 10 digits
                                    e.target.value = newValue;
                                    handleChange(e); // Call handleChange to update state
                                }}
                                maxLength="10" // Limit input length
                                required
                                className={`form-input ${errors.mobile ? 'input-error' : ''}`}
                            />
                            {errors.mobile && <p className="error-message">{errors.mobile}</p>}
                        </div>
                        <div className='form-wrapper1'>
                            <select
                                name="mode"
                                value={formData.mode}
                                onChange={handleChange}
                                required
                                className="form-select"
                            >
                                <option value="FASTAG">Fastag</option>
                                <option value="IRCTC">IRCTC PNR</option>
                            </select>
                        </div>
                        <div className='form-wrapper1'>
                            <input
                                type="text"
                                name="value"
                                placeholder={formData.mode === "FASTAG" ? "Vehicle no" : "PNR No"}
                                value={formData.value}
                                onChange={handleChange}
                                onInput={(e) => {
                                    if (formData.mode === "IRCTC") {
                                        // For IRCTC, allow only digits and limit the length to 10
                                        const newValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10); // Remove non-numeric characters and limit to 10 digits
                                        e.target.value = newValue;
                                        handleChange(e); // Call handleChange to update state
                                    }
                                }}
                                maxLength="10"
                                required
                                className={`form-input ${errors.value ? 'input-error' : ''}`}
                            />
                            {errors.value && <p className="error-message">{errors.value}</p>}
                        </div>
                        {isotpSent && (<div className='form-wrapper1'>
                            <input
                                type="text"
                                name="otp"
                                placeholder="Enter OTP"
                                value={formData.otp}
                                onChange={handleChange}
                                required
                                className={`form-input ${errors.otp ? 'input-error' : ''}`}
                                maxLength="4" // Limit to 4 characters
                                pattern="\d{4}" // Ensure only digits are allowed
                            />
                            {errors.otp && <p className="error-message">{errors.otp}</p>}
                            <p className='success-message' style={{ color: 'orange', marginTop: -5 }}>
                                Resend OTP in {timer} seconds&nbsp;
                                <span style={{ color: timer === 0 ? 'orange' : 'gray', borderBottom: "1px solid" }} onClick={(e) => timer === 0 && handleSubmit(e)} >Click Here</span>
                            </p>
                            {successMessage && <p className='success-message' style={{ color: 'green', marginTop: -15 }}>{successMessage}</p>}
                        </div>)}

                        {serverMessage && (
                            <p className={`server-message ${serverMessage.type === 'error' ? 'error-message-server' : 'success-message'}`}>
                                {serverMessage.message}
                            </p>
                        )}
                        {/* Resend OTP button */}

                        <button type="submit"
                            className={`form-button ${Object.values(errors).some((error) => error) || !formData.name || !formData.mobile || !formData.value ? 'disabled' : ''}`}
                            disabled={Object.values(errors).some((error) => error) || !formData.name || !formData.mobile || !formData.value}>
                            {isLoading ? <div className="loader"></div> : 'Submit'}
                        </button>

                        <div style={{ textAlign: 'left', fontWeight: 300, marginTop: "20px" }}>
                            <p>Please enter the details correctly to enable us to serve you better.</p>
                            <p>By Clicking this button, you agree with our<br />
                                <span style={{ color: "#2567E8" }}>Terms and Conditions</span>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;
