import { Routes, Route, } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import ScanCode from "./pages/scan/ScanYourCode";
import ScanSearch from "./pages/scan/scan_search";
import Vehcile from "./pages/scan/ScanYourCode/vehicle";
import Restricted from "./pages/scan/ScanYourCode/restricted";
import Proceed from "./pages/scan/ScanYourCode/proceed";
import Confirm from "./pages/scan/ScanYourCode/confirm";
import Final from "./pages/scan/ScanYourCode/final";
import DesktopError from "./pages/desktopError";
import WebCall from "./pages/WebCall";
import Error from "./pages/WebCall/error";
import Test from './pages/Test';
import { d1 } from "./components/utils";
import Redirect from "./pages/redirect";

function Router() {
  const { host } = window.location;
  const domain = host.split(".")[0];


  return (
    <BrowserRouter>
      {d1 === domain ? (
        <Routes>
          <Route path="/" element={<Final />} />
          <Route path="/report-type" element={<ScanSearch />} />
          <Route path="/vehicle-no" element={<Vehcile />} />
          <Route path="/restricted" element={<Restricted />} />
          <Route path="/proceed/:user_url" element={<Proceed />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/scan" element={<ScanCode />} />
          <Route path="/desktop-error" element={<DesktopError />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/:user_url" element={<Login />} />
          <Route path="/webcall/" element={<WebCall />} />
          <Route path="/test" element={<Test />} />
          <Route path="/error/" element={<Error />} />
          <Route path="*" element={<Redirect />} />
          <Route path="/desktop-error" element={<DesktopError />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default Router;
